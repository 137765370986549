@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.activator-btn {
  background-color: var(--color-control-background-theme);
}

// Vuetify's <v-menu> wrapper
.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}
.container {
  padding-top: 20px;
  width: 350px;
  border: 1px solid var(--color-control-contrast-theme);
  background-color: var(--color-flat-panel-theme);
}
