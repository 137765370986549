@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// Over-rule Vuetify styles
.v-toolbar {
  &.v-sheet {
    &,
    &.v-app-bar {
      background-color: transparent;
      box-shadow: none;
    }
  }
  // .v-toolbar__content
  ::v-deep &__content {
    // Vertical alignment with `main-page` container
    padding-inline: $grid-gutter;
    margin-inline: -($grid-gutter);
  }
  // .v-toolbar__title, .v-toolbar__items
  ::v-deep &__title,
  ::v-deep &__items {
    height: auto; // over-rule `height:inherit` to parent's `56px|64px`

    > .v-btn {
      border-radius: var(--border-radius-form-elements);
    }
  }
  ::v-deep &__items {
    display: flex;
  }
}

// Wx styles
.wx-page-toolbar {
  @media ($wx-xs-max) {
    height: auto !important; // over-rule 56px
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-border-theme);

    &,
    ::v-deep .v-toolbar {
      // .v-toolbar__content
      &__content {
        display: block !important;
        height: auto !important; // over-rule 56px
      }
      // .v-toolbar__title
      &__title {
        margin-bottom: var(--grid-gutter);
      }
    }
  }
  @media ($wx-sm-min) {
    .v-toolbar {
      &__title,
      &__items {
        width: 50%; // force .v-toolbar__title to fill its half of the space
      }
      &__title {
        margin-right: calc(var(--grid-gutter) / 2);
      }
    }
  }
  /**
   * section.wx-page-toolbar__items--single-column
   */
  &__items {
    &--single-column {
      display: flex;
      width: 100%;
    }
  }
  /** - - - - - - - - - - - - - -
  * !!!Sticky feature is postpone : #designerWishList
   * Sticky on scroll out
   */
  &.sticky-toolbar {
    // .sticky-toolbar--intersection-indicator
    &--intersection-indicator {
      visibility: hidden;
      height: 1px;
    }
    // .sticky-toolbar--top
    &--top {
      position: sticky;
      z-index: 1;
      top: var(--v-application-top); // match the app-main-header height value

      @media ($wx-md-min) {
        top: 0;
      }
      // constrain height within 56px ($vuetify.application.top)
      ::v-deep .v-toolbar__content {
        padding-top: 0;
        padding-bottom: 0;
        background-color: rgba(yellow, 0.1); // ...........DEVdebug TODO
        background-image: var(--repeating-oblique-small-pattern); // ...........DEVdebug TODO
      }
    }
  }
}
