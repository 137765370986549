@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-card {
  &.wx-panel {
    position: relative;
  }
  // !isMobile = vertical scrollbar
  @media ($wx-md-min) {
    // .v-card__text
    &__text {
      flex-grow: 1;
      overflow-y: auto;
      max-height: 100%;

      // browser hack, non-standard CSS pseudo-elements
      &::-webkit-scrollbar {
        width: calc(var(--grid-gutter) / 3);
        background-color: var(--color-border-theme);
        border-radius: var(--border-radius-sm);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-text-subtle-theme);
        border-radius: var(--border-radius-sm);
      }
    }
  }
}

.v-list {
  padding-block: calc(var(--border-radius-sm) / 2);

  &.v-sheet {
    background-color: transparent;
  }
  .v-list-item {
    padding-inline: var(--wx-panel-container-padding);
  }
}
