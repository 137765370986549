@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// wx-page-toolbar
.factory-selector-list {
  max-width: 400px;
}
.wx-date-range-picker.filter-form {
  @media ($wx-xs-max) {
    max-width: 100%;
  }
}
.aside-filter-items {
  &__filters,
  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__filters {
    flex-wrap: wrap;
    margin-bottom: var(--field-vertical-gutter);

    .drawer-activator-btn.v-size--large {
      height: 41px; // match wx-selext on hover
      white-space: nowrap;
    }
  }

  .v-chip,
  .v-alert {
    max-width: 10rem; // @320px
  }

  @media (min-width: $wx-breakpoint-phone-vertical) {
    .v-chip,
    .v-alert {
      max-width: 16rem; // @420px
    }
  }
  @media ($wx-sm-min) {
    .v-chip,
    .v-alert {
      max-width: 25rem; // @600px
    }
  }
  // flex displayed inline breakpoint
  @media ($wx-md-min) {
    &__filters {
      margin-bottom: 0;
    }
  }
  @media ($wx-lg-min) {
    .v-chip,
    .v-alert {
      max-width: 45rem; // @1264px
    }
  }
  @media ($wx-xl-min) {
    .v-chip,
    .v-alert {
      max-width: 80rem; // @1904px
    }
  }
}

/** - - - - - - - - - - - - - -
 * Page contents
 */

// 1st Section
.tile-container {
  .main-tile,
  .wx-panel {
    &--tile {
      background-color: transparent;
    }

    .v-card {
      &__title,
      &__text {
        padding: 0;
      }

      &__title {
        line-height: 1.22;
      }
      &__text {
        &.value {
          font-size: var(--font-size-h2);

          .percentage-after::after {
            content: "%";
          }
        }
        &.progress-bar {
          display: none;
        }
      }
    }

    // !isMobile
    @media ($wx-md-min) {
      .v-card {
        &__title {
          padding: var(--wx-panel-container-padding);
        }
        &__text {
          padding-inline: var(--wx-panel-container-padding);

          &.value {
            min-height: 1.4rem;
            font-size: var(--font-size-norm);
          }
          &.progress-bar {
            display: block;
          }
        }
      }
    }
  }

  .wx-panel {
    &--tile {
      padding: 0;

      // !isMobile
      @media ($wx-md-min) {
        // duplicate style from `.wx-panel`
        border-radius: var(--border-radius-sm);
        background-color: var(--color-base-background);

        .v-card {
          &__text {
            &:last-child {
              padding-top: 10px;
              min-height: 42px;
              padding-bottom: var(--wx-panel-container-padding);
            }
          }
        }
      }
    }
  }
}

// 2nd Section
.graph {
  &__header {
    .select-metric {
      max-width: 15rem;
    }
  }
  &__container {
    position: relative;
  }
}
