@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.container {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 200px;
  background-color: var(--color-flat-panel-theme);
}
.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}
