@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

fieldset.fields {
  border: none;
}
.instructions-text {
  padding: 0 10px;
  color: var(--color-text-subtle-theme);
  font-size: var(--font-size-xs);
  line-height: 1.3;
}

// Tabs styles as group buttons
.v-application {
  .v-tabs {
    &.btn-toggle-tabs {
      background-color: var(--color-border-theme);
      padding: 2px;
      border-radius: calc(var(--border-radius-sm) + 1px);

      .v-tabs-bar {
        .v-tab {
          border-radius: var(--border-radius-sm);
          overflow: hidden;
          border-bottom-width: 0;
          border: none;

          &--active {
            color: var(--color-text-theme);
            background-color: var(--color-element-layer1) !important;

            &::before {
              opacity: 0 !important;
              // ux = disabled
              pointer-events: none;
              cursor: auto;
            }
          }
        }
      }
    }
  }
}
