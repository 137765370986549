@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// style
.v-navigation-drawer {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: var(--color-flat-panel-theme);

  @media ($wx-sm-min) {
    width: 320px !important;
  }
  @media ($wx-xl-min) {
    width: 400px !important;
  }

  ::v-deep .aside-drawer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: var(--box-padding-dashboard);

    &__header {
      padding-inline: var(--box-padding-dashboard);

      .v-tabs {
        &--grow {
          .v-tabs-bar {
            &--show-arrows {
              .v-slide-group {
                &__prev,
                &__next {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: var(--box-padding-dashboard);
      column-gap: var(--box-padding-dashboard);
      background-color: var(--color-flat-panel-theme);
      border-top: 1px solid var(--color-border-theme);

      // Better UX when hidden contents with `.scrollbar-y-container`
      $boxShadow_color: hsl(216, 91%, 4%);

      .theme--dark & {
        box-shadow: 0 -3px 2px -2px rgba($boxShadow_color, 0.2), 0 -2px 3px 0 rgba($boxShadow_color, 0.14),
          0 -1px 6px 0 rgba($boxShadow_color, 0.12);
      }
      .theme--light & {
        box-shadow: 0 -3px 4px -2px rgba($boxShadow_color, 0.07), 0 -2px 6px 0 rgba($boxShadow_color, 0.03),
          0 -1px 10px 0 rgba($boxShadow_color, 0.02);
      }

      .v-btn {
        flex-grow: 1;
      }
    }
    &__body {
      margin-bottom: 55px; // bottom margin = aside-drawer_footer height
      @media ($wx-sm-min) {
        margin-bottom: 63px;
      }
      @media ($wx-lg-min) {
        margin-bottom: 71px;
      }
      padding: 0.75rem var(--box-padding-dashboard) var(--box-padding-dashboard);

      &.scrollbar-y-container {
        padding-right: calc(var(--box-padding-dashboard) - var(--scrollbar-y-width));
      }

      // fix for duplicated footers within each tabs
      .aside-drawer__footer {
        margin-inline: calc(-1 * (var(--box-padding-dashboard)));
      }

      /**
       * Styling ExistingFilterGroup options
       */
      .saved-filter-group {
        &.filled-text-truncate {
          &.v-text-field--filled {
            // if prop `append-icon` on <wx-text-field>
            .v-text-field__slot {
              input {
                max-width: 14rem; // 24x24 icon on the right
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            // if prop `clearable` on <wx-select>
            .v-select__selection {
              max-width: 11rem;
              overflow: hidden;
              text-overflow: ellipsis;

              @media ($wx-sm-min) {
                max-width: 13rem;
              }
              @media ($wx-lg-min) {
                max-width: 12rem;
              }
            }
          }
        }
      }
    }
  }
}
