@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.summary-text {
  margin-bottom: 0;
  padding: 0 10px;
  color: var(--color-text-subtle-theme);
  font-size: var(--font-size-xs);
  line-height: 1.3;
  text-transform: uppercase;
}

// <v-textarea filled>
::v-deep .v-textarea {
  &.v-text-field {
    &--filled {
      &.v-input {
        > .v-input__control {
          > .v-input__slot {
            border-radius: var(--border-radius-form-elements);

            // hide border-bottom
            &::before,
            &::after {
              display: none;
              border-color: transparent !important;
            }
          }
        }
        // vertical gutter match wx-text-field
        textarea {
          margin-bottom: 12px;
        }
        &--dense {
          textarea {
            margin-bottom: 6px;
          }
        }
      }
    }
    // prop `dense`
    &--enclosed {
      &.v-input--dense {
        .v-text-field__details {
          margin-bottom: 1px;
        }
      }
    }
  }
}
